import React from "react"
import { Link } from "gatsby-plugin-modal-routing"

import ConditionalLayout from "../components/ConditionalLayout"
import SEO from "../components/SEO"
import Video from "../components/Video"
import VideoNoWrapper from "../components/VideoNoWrapper"

const WatchVideo = () => (
  <ConditionalLayout>
    <SEO title="Watch Video" />
    <VideoNoWrapper width="600" height="600"/>
  </ConditionalLayout>
)

export default WatchVideo
