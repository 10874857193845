// import { Link } from "gatsby"
import React from 'react'

const VideoNoWrapper = ({
  width = "400",
  height = "400",
}) => (
        <iframe src="https://player.vimeo.com/video/414926527" width={width} height={height} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    )

export default VideoNoWrapper
